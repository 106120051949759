import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getEatingData,
  getProductionData,
  getEnvironmentData,
  getScores,
  getName,
  getTag,
  getFinalScore,
  getAnimalForSaving,
  demoIsSaving,
  getDemoId,
  getApiError
} from '../../GBIDemo/reducer';
import PropTypes from 'prop-types';
import GBIButton from '../../util/buttons/components/GBIButton';
import './animal-spec.scss';
import { Link, Redirect } from 'react-router-dom';
import DataSection from './DataSection';
import conceptImage from '../../../img/logo/gbi-results-logo.svg';
import Modal from '../../main/components/Modal';
import { isAuthenticated } from '../../util/jwt';
import { saveDemoAnimal, demoReset } from '../../GBIDemo/actions';
import ViewIcon from '../../util/icons/components/ViewIcon';
import GBILogin from '../../user/components/GBILogin';
import { isLoggedIn } from '../../user/reducer';
import QRCode from 'qrcode.react';
import LoadingIcon from '../../util/icons/components/LoadingIcon';
import {
  getGrade,
  getShortGradeDescription
} from '../../admin/scoreDefinitions/util';
import { getScoreDefinitions } from '../../admin/scoreDefinitions/reducer';
import GBIGradeShield from '../../util/logos/GBIGradeShield';
import analytics from '../../util/analytics';

const mapStateToProps = state => ({
  production: getProductionData(state),
  eating: getEatingData(state),
  environment: getEnvironmentData(state),
  scores: getScores(state),
  score: getFinalScore(state),
  name: getName(state),
  tag: getTag(state),
  animalForSaving: getAnimalForSaving(state),
  saving: demoIsSaving(state),
  apiError: getApiError(state),
  animalId: getDemoId(state),
  loggedIn: isLoggedIn(state),
  scoreDefinitions: getScoreDefinitions(state)
});

const mapDispatchToProps = dispatch => ({
  save: animal => dispatch(saveDemoAnimal(animal)),
  startAgain: () => dispatch(demoReset())
});

export class AnimalSpec extends Component {
  constructor (props) {
    super(props);
    this.state = {
      showLogin: false,
      showQRCode: false
    };
    this.startTime = new Date();
    this.animateScore = this.animateScore.bind(this);
  }
  componentDidMount () {
    this.animateScore();
    const spec = document.getElementById('animal-spec');
    if (spec) {
      spec.scrollIntoView({ behavior: 'smooth' });
    }
  }
  componentDidUpdate (prevProps) {
    if (prevProps.animalId !== this.props.animalId) {
      const showQRCode = !!this.props.animalId;
      this.setState({ showQRCode });
    }
  }
  animateScore () {
    const runTime = 2000;
    const scoreElement = document.getElementById('score');
    if (!scoreElement) {
      return;
    }
    const now = new Date();
    const timePassed = now - this.startTime;
    const rawScore =
      Math.pow((timePassed / runTime), 0.2) * this.props.score;
    const currentScore = Math.round(rawScore);
    scoreElement.innerHTML = currentScore;
    if (currentScore < this.props.score) {
      window.requestAnimationFrame(this.animateScore);
    }
  }
  closeModal () {
    this.setState({ showLogin: false, showSavingModal: false });
  }
  startTryItOutAgain () {
    this.props.startAgain();
    this.props.history.push('/rate-my-animal');
  }
  renderApiError () {
    if (this.props.apiError) {
      try {
        const errors = this.props.apiError.split(',\n').map(error => (
          <div className="error" key={error}>{ error }</div>
        ));
        return (
          <div className="api-error">
            { errors }
          </div>
        );
      } catch (error) {
        return (
          <div className="api-error">
            { this.props.apiError }
          </div>
        );
      }
    }
    return '';
  }
  handleSaveClicked () {
    if (isAuthenticated()) {
      this.props.save(this.props.animalForSaving);
      // this.props.save();
      this.setState({ showQRCode: true });
    } else {
      this.setState({ showLogin: true, showQRCode: true });
    }
  }
  renderSaveButton () {
    if (this.props.animalId) {
      return (
        <GBIButton className="save-spec"
          onClick={ () => this.setState({ showQRCode: true })}>
          <ViewIcon />
          <span className="text">View your QR Code</span>
        </GBIButton>
      );
    }
    return (
      <GBIButton className="save-spec"
        onClick={ this.handleSaveClicked.bind(this) }>
        <span className="icon">+</span>
        <span data-cy="save-to-your-account"
          className="text">Save to your account</span>
      </GBIButton>
    );
  }
  renderModal () {
    let modal = null;
    if (this.state.showLogin && !this.props.loggedIn) {
      const actionOnSuccess = () => this.props.save(this.props.animalForSaving);
      modal = (
        <Modal close={ () => this.setState({ showLogin: false })}
          pos={ window.scrollY }>
          <h1 className="animal-spec-modal-title">Please log in</h1>
          <GBILogin className="modal"
            actionOnSuccess={ actionOnSuccess }/>
        </Modal>
      );
    } else if (this.state.showQRCode) {
      const close = () => this.setState({ showQRCode: false });
      let content = '';
      if (this.props.saving) {
        content = (
          <div className="saving">
            <LoadingIcon />
          </div>
        );
      } else if (!this.props.animalId) {
        content = (
          <div className="qr-code-and-link error">
            <h3>error saving animal</h3>
            { this.renderApiError() }
          </div>
        );
      } else {
        const id = this.props.animalId;
        const domain = window.location.hostname;
        const link = `https://${domain}/animal/${id}`;
        const title = this.props.animalForSaving.name ||
          this.props.animalForSaving.tag;
        content = (
          <div className="qr-code-and-link success">
            <Link to={ `/animal/${id}` }>
              <h3 data-cy="saved-title">{ title + ' Saved' }</h3>
              <h5 className="link">
                { link }
              </h5>
            </Link>
            <QRCode value={ link } />
            <div className="button-row">
              <GBIButton label="close" className="light" onClick={ close } />
              <GBIButton
                label="start again"
                onClick={ this.startTryItOutAgain.bind(this) } />
            </div>
          </div>
        );
      }
      modal = (
        <Modal close={ close } pos={ window.scollY }>
          { content }
        </Modal>
      );
    }
    return modal;
  }
  render () {
    let grade = null;
    let description = null;
    try {
      grade = getGrade(
        this.props.production,
        this.props.eating,
        this.props.environment,
        this.props.scoreDefinitions
      );
      description = getShortGradeDescription(grade);
    } catch (error) {
      return <Redirect to="/" />;
    }
    analytics(
      'send',
      'event',
      'Signup',
      'tryItOutCompleted',
      grade
    );
    return (
      <div id='animal-spec'>
        <div className="concept-image-wrapper">
          <img src={ conceptImage } className="gbi-concept-image" />
          <div className="result">
            <div data-cy="gbi-score" id="score">{ ' ' }</div>
            <div className="out-of">{ '/120' }</div>
          </div>
        </div>
        <div className="top">
          <h4 className="your-results">your results</h4>
          <h1 data-cy="animal-name" className="name">{ this.props.name}</h1>
          <h5 className="tag">{ this.props.tag }</h5>
          <h2 data-cy="gbi-grade" className="category">
            <span className="label">grade: </span>
            <GBIGradeShield grade={ grade } />
          </h2>
          <p className="category-description">
            { description }
          </p>
          <DataSection { ...this.props }/>
        </div>
        <div className="footer" >
          <p className="next-move">What to do with your results</p>
          <div className="options">
            { this.renderSaveButton() }
          </div>
        </div>
        { this.renderModal() }
      </div>
    );
  }
}

AnimalSpec.propTypes = {
  production: PropTypes.object,
  eating: PropTypes.object,
  environment: PropTypes.object,
  scores: PropTypes.object,
  score: PropTypes.number,
  name: PropTypes.string,
  tag: PropTypes.string,
  match: PropTypes.shape({
    url: PropTypes.string
  }),
  save: PropTypes.func,
  saving: PropTypes.bool,
  animalId: PropTypes.string,
  animalForSaving: PropTypes.object,
  loggedIn: PropTypes.bool,
  apiError: PropTypes.string,
  history: PropTypes.object,
  startAgain: PropTypes.func,
  scoreDefinitions: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(AnimalSpec);
