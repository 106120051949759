import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BigDonutChart from '../../util/charts/components/BigDonutChart';
import CowDataPoint from '../../cows/components/CowDataPoint';
import './data-section.scss';
import MobileBigDonutChart
  from '../../util/charts/components/MobileBigDonutChart';
import { getScoreDefinitions } from '../../admin/scoreDefinitions/reducer';
import { getElementFactors } from '../../admin/scoreDefinitions/util';

const mapStateToProps = state => ({
  scoreDefinitions: getScoreDefinitions(state)
});

class DataSection extends Component {
  constructor (props) {
    super(props);
    this.state = {
      openSection: null,
      version: window.innerWidth > 500 ? 'desktop' : 'mobile'
    };
    this.containerRef = React.createRef();
    this.showSection = this.showSection.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }
  componentDidMount () {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount () {
    window.removeEventListener('resize', this.handleResize);
  }
  showSection (openSection) {
    if (this.state.openSection === openSection) {
      this.setState({ openSection: null });
    } else {
      this.setState({ openSection });
    }
  }
  handleResize () {
    const version = window.innerWidth > 500 ? 'desktop' : 'mobile';
    this.setState({ version });
  }
  getTriangleStyle () {
    let percentageWidth = 0;
    switch (this.state.openSection) {
      case 'production':
        percentageWidth = 16.6;
        break;
      case 'eating':
        percentageWidth = 50;
        break;
      case 'environment':
        percentageWidth = 83.4;
        break;
    }
    return { left: percentageWidth + '%' };
  }
  getCategoryScore (category) {
    try {
      return Object.values(this.props[category]).reduce((a, b) => a + b, 0);
    } catch (error) {
      return 0;
    }
  }
  renderBigDonuts () {
    let donutElements = [];
    donutElements.push(
      <MobileBigDonutChart
        key="production"
        onClick={() => this.showSection('production')}
        score={this.getCategoryScore('production')}
        type="production" />
    );
    donutElements.push(
      <MobileBigDonutChart
        key="eating"
        onClick={() => this.showSection('eating')}
        score={this.getCategoryScore('eating')}
        type="eating" />
    );
    donutElements.push(
      <MobileBigDonutChart
        key="environment"
        onClick={() => this.showSection('environment')}
        score={this.getCategoryScore('environment')}
        type="environment" />
    );
    return donutElements;
  }
  renderDonuts () {
    if (this.state.version === 'mobile') return this.renderBigDonuts();
    let donutElements = [];
    if (this.props.production) {
      donutElements.push(
        <BigDonutChart
          key="production"
          onClick={() => this.showSection('production')}
          score={this.getCategoryScore('production')}
          type="production" />
      );
    }
    if (this.props.eating) {
      donutElements.push(
        <BigDonutChart
          key="eating"
          onClick={() => this.showSection('eating')}
          score={this.getCategoryScore('eating')}
          type="eating" />
      );
    }
    if (this.props.environment) {
      donutElements.push(
        <BigDonutChart
          key="environment"
          onClick={() => this.showSection('environment')}
          score={this.getCategoryScore('environment')}
          type="environment" />
      );
    }
    return donutElements;
  }
  renderTableContents () {
    if (this.state.openSection === null) return;
    const sectionData = this.props[this.state.openSection];
    const keys = getElementFactors(
      this.state.openSection,
      this.props.scoreDefinitions
    );
    return keys.map(key => {
      const data = {
        score: sectionData[key],
        supportingInfo: []
      };
      return (
        <CowDataPoint match={this.props.match}
          key={key} attribute={key} data={data} />
      );
    });
  }
  render () {
    if (this.props.production ||
    this.props.eating || this.props.environment) {
      const noClass = this.state.openSection ? 'figures visible' : 'figures';
      const triangleClass = this.state.openSection ?
        'triangle' : 'hidden triangle';
      return (
        <article id='cow-data-section' ref={this.containerRef}>
          <div className='bb-page-wrapper'>
            <div className='donuts'>
              { this.renderDonuts() }
            </div>
            <div className={ noClass }>
              <div className="pointer-wrapper">
                <div className={ triangleClass }
                  style={ this.getTriangleStyle()}>
                </div>
              </div>
              <div className="table">
                { this.renderTableContents() }
              </div>
            </div>
            <div className="border"></div>
          </div>
        </article>
      );
    }
    return null;
  }
};

DataSection.propTypes = {
  production: PropTypes.object,
  eating: PropTypes.object,
  environment: PropTypes.object,
  score: PropTypes.number,
  match: PropTypes.shape({
    params: PropTypes.object
  }),
  scoreDefinitions: PropTypes.object
};

export default connect(mapStateToProps)(DataSection);
