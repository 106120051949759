import React, { Component } from 'react';
import { connect } from 'react-redux';
import { login } from '../actions';
import PropTypes from 'prop-types';
import GBIButton from '../../util/buttons/components/GBIButton';
import './gbi-login.scss';
import history from '../../goodBeefIndex/gbiHistory';
import { currentlyLoggingIn } from '../reducer';
import { Link } from 'react-router-dom';
import Signup from '../signup/components/Signup';
import { Helmet } from 'react-helmet';
import HideIcon from '../../util/icons/components/HideIcon';
import ShowIcon from '../../util/icons/components/ShowIcon';

const mapStateToProps = state => ({
  loggingIn: currentlyLoggingIn(state)
});

const mapDispatchToProps = dispatch => ({
  login: (user, password, next, history, action) =>
    dispatch(login(user, password, next, history, action))
});

class GBILogin extends Component {
  constructor () {
    super();
    this.login = this.login.bind(this);
    this.userRef = React.createRef();
    this.passwordRef = React.createRef();
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      showingPassword: false
    };
  }

  componentDidMount () {
    window.scrollTo({ top: 0, behaviour: 'auto' });
  }

  login () {
    if (this.props.loggingIn) return;
    const user = this.userRef.current.value;
    const password = this.passwordRef.current.value;
    const actionOnSuccess = this.props.actionOnSuccess || null;
    let next = '/account';
    try {
      next = this.props.location.state.from.pathname;
    } catch (error) { /* */ }
    this.props.login(user, password, next, history, actionOnSuccess);
  }

  handleClick (event) {
    event.stopPropagation();
  }

  togglePasswordVisibility () {
    this.setState({ showingPassword: !this.state.showingPassword });
  }

  render () {
    const buttonClass = this.props.loggingIn ? 'disabled' : '';
    const canon = `${window.location.origin}/${this.props.location.pathname}`;
    const meta = `Login to the GoodBeef Index. Upload your animals, and 
      download QR codes so consumers can trace their beef`;
    const showHideIcon = this.state.showingPassword ?
      <HideIcon /> : <ShowIcon />;
    const passwordProps = {
      ref: this.passwordRef,
      'data-cy': 'password-input',
      placeholder: 'password',
      className: 'password',
      type: this.state.showingPassword ? 'text' : 'password'
    };
    return (
      <div id="gbi-login-page" className={ this.props.className || '' }>
        <Helmet>
          <title>Login to you account on the GoodBeef Index</title>
          <meta name="description" content={ meta } />
          <link rel="canonical"
            href={ canon } />
        </Helmet>
        <form className="gbi-login-form"
          onClick={ this.handleClick }
          data-cy="gbi-login-form">
          <input ref={this.userRef}
            className="user"
            placeholder="email"
            data-cy="email-input"
            autoCapitalize="off" />
          <div className="password-wrapper">
            <input { ...passwordProps } />
            <div className="show-hide"
              onClick={ this.togglePasswordVisibility.bind(this) }>
              { showHideIcon }
            </div>
          </div>
          <div className="button-row right">
            <Link className="forgotten-password" to="/forgotten-password">
              Forgotten password?
            </Link>
            {/* <Link className="register" to="/register">Join</Link> */}
            <Signup id="login-page-join-link" buttonLabel="Join" />
            <GBIButton className={ buttonClass }
              testId="login-button"
              label="Login"
              onClick={this.login} />
          </div>
        </form>
      </div>
    );
  }
}

GBILogin.propTypes = {
  login: PropTypes.func.isRequired,
  loggingIn: PropTypes.bool,
  className: PropTypes.string,
  actionOnSuccess: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    state: PropTypes.object
  })
};

export default connect(mapStateToProps, mapDispatchToProps)(GBILogin);
