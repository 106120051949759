import React from 'react';
import PropTypes from 'prop-types';
import './close-icon.scss';

/* eslint-disable max-len */
const ViewIcon = ({ onClick }) => (
  <svg onClick={ onClick } className='bb-view-icon' viewBox="0 0 98.55 98.55"><path d="M50,12A19,19,0,1,0,69,31,19,19,0,0,0,50,12Zm0,32A13,13,0,1,1,63,31,13,13,0,0,1,50,44Z" transform="translate(-0.74 -0.7)"/><path d="M98.38,28.88,84.48,15a48.77,48.77,0,0,0-69,0L1.62,28.88a3,3,0,0,0,0,4.24L15.52,47a48.77,48.77,0,0,0,69,0l13.9-13.9A3,3,0,0,0,98.38,28.88ZM80.24,42.78a42.78,42.78,0,0,1-60.48,0L8,31,19.76,19.22a42.78,42.78,0,0,1,60.48,0L92,31Z" transform="translate(-0.74 -0.7)"/><path d="M50,12A19,19,0,1,0,69,31,19,19,0,0,0,50,12Zm0,32A13,13,0,1,1,63,31,13,13,0,0,1,50,44Z" transform="translate(-0.74 -0.7)"/><path d="M98.38,28.88,84.48,15a48.77,48.77,0,0,0-69,0L1.62,28.88a3,3,0,0,0,0,4.24L15.52,47a48.77,48.77,0,0,0,69,0l13.9-13.9A3,3,0,0,0,98.38,28.88ZM80.24,42.78a42.78,42.78,0,0,1-60.48,0L8,31,19.76,19.22a42.78,42.78,0,0,1,60.48,0L92,31Z" transform="translate(-0.74 -0.7)"/></svg>
);

ViewIcon.propTypes = {
  onClick: PropTypes.func
};

export default ViewIcon;
